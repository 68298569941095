import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { ProfileService } from '../../shared/profile.service';

@Component({
  selector: 'app-text-signature',
  templateUrl: './text-signature.component.html',
  styleUrls: ['./text-signature.component.scss'],
})
export class TextSignatureComponent
  implements AfterViewChecked, OnDestroy
{
  @Input() abbreviatedName: string;
  @Input() finalTargetHeight = 300;
  @Input() fullName: string;
  @Input() onlyFullName = false;
  @Input() selectOnly = false;
  @Input() selectedSignature: TextSignatureType = 'full';
  @Output() abbreviatedNameChange = new EventEmitter<string>();
  @Output() fullNameChange = new EventEmitter<string>();
  @Output() cancelClick = new EventEmitter();
  @Output() signatureSaveClick = new EventEmitter<Blob>();
  @ViewChild('abbreviatedNameCanvas')
  abbreviatedNameCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('fullNameCanvas')
  fullNameCanvas: ElementRef<HTMLCanvasElement>;
  themeList: ThemeList;
  subscription: Subscription[] = [];
  constructor(
    private profileService: ProfileService,
    private themeService: ThemeService,
  ) {
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  ngAfterViewChecked(): void {
    /** Resize and redraw when the size of canvas change  */
    if (this.isCanvasFullWidth(this.fullNameCanvas)) {
      this.initializeFullNameCanvas();
    }
    if (this.onlyFullName) {
      return;
    }
    if (this.isCanvasFullWidth(this.abbreviatedNameCanvas)) {
      this.initializeAbbreviatedNameCanvas();
    }
  }

  /** Adjusted the width of canvas to equal its element */
  adjustCanvasToFullWidth(
    elRef: ElementRef<HTMLCanvasElement>,
  ): void {
    elRef.nativeElement.width = elRef.nativeElement?.clientWidth;
  }

  initializeAbbreviatedNameCanvas(): void {
    this.adjustCanvasToFullWidth(this.abbreviatedNameCanvas);
    if (!this._abbreviatedName) {
      return;
    }
    this.redrawTextSignature(
      this._abbreviatedName,
      this.abbreviatedNameCanvas.nativeElement,
      true,
    );
  }

  initializeFullNameCanvas(): void {
    this.adjustCanvasToFullWidth(this.fullNameCanvas);
    if (!this._fullName) {
      return;
    }
    this.redrawTextSignature(
      this._fullName,
      this.fullNameCanvas.nativeElement,
      true,
    );
  }

  /** Check that does the width of canvas the equal element width */
  isCanvasFullWidth(elRef: ElementRef<HTMLCanvasElement>): boolean {
    const canvasWidth = elRef.nativeElement.width;
    const canvasClientWidth = elRef.nativeElement.clientWidth;
    return canvasWidth !== canvasClientWidth;
  }

  finiteSignature(): Observable<Blob> {
    const setFontSize = (size = 18) => {
      ctx.font = `${size}px Charmonman`;
    };
    /** Create new instance canvas */
    const newCanvas = document.createElement('canvas');
    newCanvas.style.display = 'none';
    newCanvas.height = this.finalTargetHeight;
    /** Adjust the size of canvas */
    const ctx = newCanvas.getContext('2d');
    const fontSize = this.finalTargetHeight * 0.5;
    setFontSize(fontSize);
    const textWidth = ctx.measureText(
      this.selectedSignatureName,
    ).width;
    newCanvas.width = textWidth + fontSize * 1.5;
    setFontSize(fontSize);
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      this.selectedSignatureName,
      newCanvas.width * 0.5,
      newCanvas.height * 0.55,
    );
    /** The final signature image */
    const subject = new Subject<Blob>();
    newCanvas.toBlob((res) => {
      subject.next(res);
    });
    return subject.pipe(take(1));
  }

  onCancelClick(): void {
    this.cancelClick.emit();
  }

  onConfirmClick(): void {
    const subscription = this.finiteSignature().subscribe({
      next: (res) => {
        const signatureFile = new File([res], 'online-sign.png', {
          type: res.type,
        });
        this.signatureSaveClick.emit(signatureFile);
      },
    });
    this.subscription.push(subscription);
  }

  onFullNameChange(text: string, canvas: HTMLCanvasElement): void {
    this.redrawTextSignature(text, canvas);
    if (this.abbreviatedName) {
      return;
    }
    this.redrawTextSignature(
      this._abbreviatedName,
      this.abbreviatedNameCanvas?.nativeElement,
    );
  }

  onTextSignatureSelect(type: TextSignatureType): void {
    this.selectedSignature = type;
  }

  redrawTextSignature(
    text: string,
    canvasElement: HTMLCanvasElement,
    initial = false,
  ): void {
    const ctx = canvasElement.getContext('2d');
    const clearContext = () => {
      ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    };
    const setFontSize = (size = 18) => {
      ctx.font = `${size}px Charmonman`;
    };

    clearContext();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    let fontSize = canvasElement.height * 0.5;
    setFontSize(fontSize);
    const textWidth = ctx.measureText(text).width;
    if (textWidth > canvasElement.width * 0.8) {
      fontSize = canvasElement.width * 0.8 * (fontSize / textWidth);
      setFontSize(fontSize);
    }
    clearContext();
    if (initial) {
      // waiting for font to be downloaded on first time
      setTimeout(() => {
        ctx.fillText(
          text,
          canvasElement.width * 0.5,
          canvasElement.height * 0.55,
        );
      }, 100);
    } else {
      ctx.fillText(
        text,
        canvasElement.width * 0.5,
        canvasElement.height * 0.55,
      );
    }
  }

  get _fullName(): string {
    return this.fullName;
  }

  set _fullName(val: string) {
    this.fullName = val;
    this.fullNameChange.emit(val);
  }

  get selectedSignatureName(): string {
    return this.selectedSignature === 'full'
      ? this.fullName
      : this._abbreviatedName;
  }

  get _abbreviatedName(): string {
    return (
      this.abbreviatedName ||
      this.profileService.abbreviateFullName(this._fullName)
    );
  }

  set _abbreviatedName(val: string) {
    this.abbreviatedName = val;
    this.abbreviatedNameChange.emit(val);
  }
}

export type TextSignatureType = 'full' | 'abbreviate';
